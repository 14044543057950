.card-image img {
  height: 100%;
  width: 100%;
}

.card-image {
  height: 100%;
  width: 100%;
  position: relative;
}

.card-image:after {
    content:'Explore';
    font-size: calc(1.2em + 1.2vw);
    font-weight: 500;
    color: #fff;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    background:rgba(0,0,0,0.4);
    opacity:0;
    transition: all 0.6s;
    -webkit-transition: all 0.6s;
}

.card-header {
  border: none;
  background-color: transparent;
}

.card.main-category {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  width: 85%;
  background-size: contain;
}

.card.main-category > .card-header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card.main-category > .card-header h2 {
  background-color: rgb(71, 117, 209);
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 5px 0 5px 0;
  line-height: 3rem;
  position: relative;
  right: 1px; bottom: 1px;
}

.see-all {
  background-color: rgb(71, 117, 209);
  text-decoration: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 0 5px 0 5px;
  line-height: 3rem;
  position: relative;
  left: 1px; bottom: 1px;
}

.see-all:hover {
  color: #fff;
}

.card.sub-category {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
  margin: 0 5px;
  min-height: 200px; max-height: 300px;
  width: 33.33%;  max-width: 350px;
  border-radius: 10px;
  transform: scale(0.95);
  transition: transform 0.8s ease;
  cursor: pointer;
  overflow: hidden;
}

.card.sub-category:hover {
  transform: scale(1);
}

.card.sub-category:hover > .card-image::after {
  opacity: 1;
}

.card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 1rem 1rem 1rem;
}

.home {
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(245, 245, 245);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.3);
}

@media (max-width : 767px) {
  .card.main-category {
    width: 90%;
  }
}
