@font-face {
    font-family: "Neuropolitical Rg";
    src: url("../fonts/neuropolitical rg.ttf");
}

.font-nueropolitical {
  font-family: "Neuropolitical Rg";
}

.product-info-block {
  height: calc(100vh - 75px);
  /* min-height: 700px; */
}

.action {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 40%;
  width: 100%;
}

.action-text {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(0.8rem + 0.4vmax);
  font-weight: 300;
  height: 100%;
  width: 50%;
  padding: 5%;
  z-index: 2;
}

.action-text.left {
  background-color: #084da2;
}

.action-text.right {
  background-color: #206ece;
}

.action-text h1 {
  text-transform: uppercase;
  font-size: calc(1.5rem + 0.5vmax);
}

.action-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  display: block;
  height: 100%;
  width: 50%;
}

#plan-image {
  background-image: url("../assets/plan_friends.svg");
}

#join-image {
  background-image: url("../assets/join_friends.svg");
}

.action-arrow-right {
  border-style: solid;
  border-width: calc(2rem + 2vmin);
  border-color: transparent transparent transparent #084da2;
  left: 49.99%;
  display: inline;
  position: absolute;
  top: 35%;
  width: auto;
  z-index: 1;
}

.action-arrow-left {
  border-style: solid;
  border-width: calc(2rem + 2vmin);
  border-color: transparent #206ece transparent transparent;
  right: 49.76%;
  display: inline;
  position: absolute;
  width: auto;
  top: 35%;
  z-index: 1;
}

.post-block {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color:#084da2;
  font-size: calc(1.5rem + 1vmax);
  height: 20%;
  width: 100%;
}

.gallery-block {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 5px;
}

.gallery-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 150px;
}

.gallery-video {
  width: 100%;
  height: 100%;
}

#gallery-image-1 {
  background-image: url("../assets/nightclub.jpg");
  grid-column: 3;
  grid-row: 1/3;
}
#gallery-image-2 {
  background-image: url("../assets/restaurant.webp");
  grid-column: 4;
  grid-row: 1;
}
#gallery-image-3 {
  background-image: url("../assets/pub.jpg");
  grid-column: 1;
  grid-row: 3/5;
}
#gallery-image-4 {
  background-image: url("../assets/trekking.jpg");
  grid-column: 2;
  grid-row: 3;
}
#gallery-image-5 {
  background-image: url("../assets/explore-city.jpg");
  grid-column: 4;
  grid-row: 2;
}
#gallery-image-6 {
  background-image: url("../assets/fun.webp");
  grid-column: 5;
  grid-row: 3/5;
}
#gallery-image-7 {
  background-image: url("../assets/concert.webp");
  grid-column: 6;
  grid-row: 3/5;
}
#gallery-image-8 {
  background-image: url("../assets/camping.webp");
  grid-column: 2;
  grid-row: 4;
}
#gallery-video-1 {
  grid-column: 1/3;
  grid-row: 1/3;
}
#gallery-video-2 {
  grid-column: 5/7;
  grid-row: 1/3;
}
#gallery-video-3 {
  grid-column: 3/5;
  grid-row: 3/5;
}


.contact-block {
  margin-top: 5px;
  color: white;
  background-color: #206ece;
  text-align: center;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-block a {
  color: white;
  font-size: 1.5rem;
  transform: scale(0.7);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.contact-block a:hover {
  color: white;
  border-bottom: 1px solid #fff;
  transform: scale(1);
}

.last-block {
  font-size: calc(0.8rem + 0.4vmax);
  font-weight: 300;
  padding: 50px 5px 20px;
  text-align: center;
}

@media (max-width: 767px) {
  #plan-image {
    background-size: cover;
  }
  .gallery-block {
    grid-template-columns: repeat(3, 1fr);
  }
  #gallery-image-1 {
    display: none;
  }
  #gallery-image-2 {
    grid-column: 3;
    grid-row: 1;
  }
  #gallery-image-3 {
    grid-column: 1;
    grid-row: 3/5;
    background-position: left;
  }
  #gallery-image-4 {
    grid-column: 2/4;
    grid-row: 3;
    background-position: top;
  }
  #gallery-image-5 {
    grid-column: 3;
    grid-row: 2;
  }
  #gallery-image-6 {
    display: none;
  }
  #gallery-image-7 {
    display: none;
  }
  #gallery-image-8 {
    grid-column: 2/4;
    grid-row: 4;
  }
  #gallery-video-1 {
    grid-column: 1/3;
    grid-row: 1/3;
  }
  #gallery-video-2 {
    display: none;
  }
  #gallery-video-3 {
    display: none;
  }
}

@media (max-width: 576.98px) {
  .product-info-block {
    height: calc(123vh - 65px);
  }

  .gallery-block {
    grid-template-columns: repeat(2, 1fr);
  }
  #gallery-image-2 {
    grid-column: 2;
    grid-row: 2;
  }
  #gallery-image-3 {
    display: none;
  }
  #gallery-image-4 {
    grid-column: 1/3;
    grid-row: 3;
    background-position: top;
  }
  #gallery-image-5 {
    grid-column: 1;
    grid-row: 2;
  }
  #gallery-image-8 {
    grid-column: 1/3;
    grid-row: 1;
  }
  #gallery-video-1 {
    display: none;
  }
}
