.bubble-button {
  display: inline-block;
  cursor: pointer;
}

.button {
  -webkit-font-smoothing: antialiased;
  border: none;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-weight: 100;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  letter-spacing: 1px;
  color: #fff;
  padding: calc(0.8rem + 0.2vmax) calc(1rem + 0.5vmax);
  font-size: calc(0.9rem + 0.1vmax);
  text-transform: uppercase;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
.button:hover {
  background: none;
  color: #fff;
}
.button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.button--bubble {
  position: relative;
  z-index: 2;
  background: none;
}
.button--bubble:hover {
  background: none;
}
.button--bubble:hover + .button--bubble__effect-container .circle {
  background: #F5BD4F;
}
.button--bubble:hover + .button--bubble__effect-container .button {
  background: #F5BD4F;
}
.button--bubble:active + .button--bubble__effect-container {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.button--bubble__container {
  position: relative;
  display: inline-block;
}
.button--bubble__container .effect-button {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 0;
  z-index: 1;
  border-radius: 2em;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #222;
  -webkit-transition: background 0.1s ease-out;
  transition: background 0.1s ease-out;
}

.button--bubble__effect-container {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  -webkit-filter: url("#goo");
  filter: url("#goo");
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  pointer-events: none;
}
.button--bubble__effect-container .circle {
  position: absolute;
  width: calc(0.8rem + 2vmin);
  height: calc(0.8rem + 2vmin);
  border-radius: 100%;
  background: #222;
  -webkit-transition: background 0.1s ease-out;
  transition: background 0.1s ease-out;
}
.button--bubble__effect-container .circle.top-left {
  top: 15%;
  left: 5%;
}
.button--bubble__effect-container .circle.bottom-right {
  bottom: 15%;
  right: 5%;
}

.goo {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

@media (max-width: 767.98px) {
  .button {
    padding: calc(0.7rem + 0.3vmax) calc(.9rem + 0.6vmax);
    font-size: calc(0.8rem + 0.1vmax);
  }
}
