.background-overlay {
  position: absolute;
  width: 25%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  background-color: #49b8ef;
  overflow: hidden;
  z-index: -1;
}

/* KEYFRAMES */

@-webkit-keyframes animateBubble {
 0% {
      margin-top: 100vh;
  }
  100% {
      margin-top: -150vh;
  }
}

@-moz-keyframes animateBubble {
 0% {
      margin-top: 100vh;
  }
  100% {
      margin-top: -150vh;
  }
}

@keyframes animateBubble {
    0% {
        margin-top: 100vh;
    }
    100% {
        margin-top: -150vh;
    }
}

@-webkit-keyframes sideWays {
    0% {
        margin-left:0px;
    }
    100% {
        margin-left:50px;
    }
}

@-moz-keyframes sideWays {
    0% {
        margin-left:0px;
    }
    100% {
        margin-left:50px;
    }
}

@keyframes sideWays {
    0% {
        margin-left:0px;
    }
    100% {
        margin-left:50px;
    }
}

/* ANIMATIONS */

.x1 {
  -webkit-animation: animateBubble 30s linear infinite, sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 30s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 30s linear infinite, sideWays 2s ease-in-out infinite alternate;

  left: -10%;
  top: 5%;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
}
 .x2 {
  -webkit-animation: animateBubble 25s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 25s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 25s linear infinite, sideWays 4s ease-in-out infinite alternate;

  left: -15%;
  top: 80%;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}
.x3 {
  -webkit-animation: animateBubble 33s linear infinite, sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 33s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 33s linear infinite, sideWays 2s ease-in-out infinite alternate;

  left: 50%;
  top: 40%;

  -webkit-transform: scale(0.7);
  -moz-transform: scale(0.7);
  transform: scale(0.7);
}

.x4 {
  -webkit-animation: animateBubble 27s linear infinite, sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 27s linear infinite, sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 27s linear infinite, sideWays 3s ease-in-out infinite alternate;

  left: 25%;
  top: 0;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x5 {
  -webkit-animation: animateBubble 34s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 34s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 34s linear infinite, sideWays 4s ease-in-out infinite alternate;

  left: -10%;
  top: 50%;

  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
}
.x6 {
  -webkit-animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;

  left: 0;
  top: 30%;

  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  transform: scale(0.8);
}

.x7 {
  -webkit-animation: animateBubble 27s linear infinite, sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 27s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 27s linear infinite, sideWays 2s ease-in-out infinite alternate;

  left: 15%;
  top: 70%;

  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  transform: scale(0.4);
}

.x8 {
  -webkit-animation: animateBubble 34s linear infinite, sideWays 3s ease-in-out infinite alternate;
  -moz-animation: animateBubble 34s linear infinite, sideWays 3s ease-in-out infinite alternate;
  animation: animateBubble 34s linear infinite, sideWays 3s ease-in-out infinite alternate;

  left: 40%;
  top: 10%;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

.x9 {
  -webkit-animation: animateBubble 31s linear infinite, sideWays 4s ease-in-out infinite alternate;
  -moz-animation: animateBubble 31s linear infinite, sideWays 4s ease-in-out infinite alternate;
  animation: animateBubble 31s linear infinite, sideWays 4s ease-in-out infinite alternate;

  left: 5%;
  top: 100%;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
}

.x10 {
  -webkit-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
  -moz-animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
  animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;

  left: 20%;
  top: 65%;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
}

/* OBJECTS */

.bubble {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2), inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  height: 200px;
  position: absolute;
  width: 200px;
  background-color: #6c757d6b;
}

.bubble:after {
  background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%); /* FF3.6+ */
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(255,255,255,0.5)), color-stop(70%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%); /* Chrome10+,Safari5.1+ */
  background: -o-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%); /* Opera 12+ */
  background: -ms-radial-gradient(center, ellipse cover,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%); /* IE10+ */
  background: radial-gradient(ellipse at center,  rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  -moz-box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);
  box-shadow: inset 0 20px 30px rgba(255, 255, 255, 0.3);

  content: "";
  height: 180px;
  left: 10px;
  position: absolute;
  width: 180px;
}

@media (max-width: 767.98px) {
  .background-overlay {
    display: none;
  }
}
