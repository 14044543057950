#friends-group-img {
  width: 100%;
  height: 100%;
}

.intro-block, .intro-text-block {
  position: static;
}

.intro-block > div {
  justify-content: center;
}

.intro-block > div > div:first-child {
  height: 60vh;
}

.intro-text-block {
  border-radius: 100%;
  padding: calc(3rem + 2vw) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  left: -10%;
  z-index: 1;
  background-color: white;
}

.intro-text-content {
  position: relative;
  left: 20%;
  text-align: center;
}

.intro-text-content h1 {
  font-size: calc(1.5rem + 0.5vmax);

}

.intro-text-content h2 {
  font-size: calc(1rem + 0.5vmax);
}

@media (max-width: 767.98px) {
  .intro-block {
    position: relative;
    z-index: 1;
    padding-bottom: 20px;
  }
  .intro-text-block {
    position: static;
    border-radius: 0;
    padding: 0;
    background-color: transparent;
    height: auto;
  }
  .intro-text-content {
    position: static;
    margin-top: 2em;
  }
}

@media (max-width: 576.98px) {
  .intro-block {
    padding-bottom: 80px;
  }
  .intro-block > div > div:first-child {
    height: auto;
    min-height: auto !important;
  }
}
