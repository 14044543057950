.listing {
  display: flex;
  flex-direction: row;
}

.listing__content {
  width: 75%; min-width: 550px;
  padding: 20px 5%;
  margin-right: auto;
}

.listing__sidebar {
  padding: 80px 20px;
  width: 25%; max-width: 250px; min-width: 200px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

.sidebar__category {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 10% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.content__duration {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.content__duration button {
  padding: 8px 16px;
  border: 1px solid rgb(80, 158, 238);
  margin-right: 5%;
  border-radius: 10em;
  color: rgb(19, 125, 235);
}

.content__duration button:hover {
  color: white;
  background-color: rgb(80, 158, 238);
}

.content__duration > .duration__button--active {
  color: #fff;
  background-color: rgb(80, 158, 238);
}

.content__post {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin-bottom: 20px;
  text-align: justify;
  border-radius: 5px;
}

#post__price {
  margin-left: 30px;
}

.post__link {
  float: right;
  background-color: rgb(71, 117, 209);
  text-decoration: none;
  color: #fff;
  padding: 0.5rem 1.5rem;
  border-radius: 5px 0 5px 0;
  line-height: 1rem;
  position: relative;
  left: 20px; top: 13px;
}

.post__link:hover {
  color: #fff;
}

.hide {
  display: none;
}
